import { Component } from '@angular/core';


@Component( {
  selector:    'ume-new-user-signup-dialog',
  templateUrl: './new-user-signup-dialog.component.html',
  styleUrls:   [ './new-user-signup-dialog.component.scss' ],
} )
export class NewUserSignupDialogComponent {
  public contentHeight = 520;

  constructor() { }
}
