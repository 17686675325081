<button
  mat-flat-button
  class="profile-menu-button"
  [matMenuTriggerFor]="menu">
    <span class="company-details">
      <span class="company-details_left">
        <i [ngClass]="'icon-flag-' + currentUser.selectedCompany?.countryCode"></i>
        <span
          class="company-details__name ellipsis text-capitalize"
          [matTooltip]="currentUser.selectedCompany?.companyName || ''"
        >
          {{ currentUser.selectedCompany?.companyName }}
        </span>
      </span>
      <span class="company-details_right">
        <mat-icon>expand_more</mat-icon>
        @if (!picLoadError && photoURL) {
          <img
            class="company-details__img img-round"
            [src]="photoURL"
            [alt]="firstName"
            (error)="loadError()">
        } @else {
          <mat-icon class="no-user-icon">account_circle</mat-icon>
        }
      </span>
    </span>
</button>

<mat-menu
  #menu="matMenu"
  xPosition="before"
  role="menu"
  class="mat-menu-xl main-menu">
  <ul class="dist-menu">
    <li>
      <span>Hi, {{ fullName }}!</span>
    </li>
    <li class="divider"></li>
    @for (company of userCompaniesToShow; track company.guid) {
      <li
        [ngClass]="{'active': company.guid === selectedCompany.guid}"
        [class.disabled]="!company.canLogin"
        [matTooltip]="!company.canLogin ? 'Ume has blocked access to this company' : ''">
        <button
          mat-button
          color="primary"
          class="w-100"
          [disabled]="!company.canLogin"
          (click)="changeCompany(company)">
          <span class="company-details">
            <i [ngClass]="'icon-flag-' + ( company.country?.code || company.countryCode )"></i>
            <span
              class="company-details__name ellipsis text-capitalize"
              [matTooltip]="company.canLogin ? company.companyName : ''">
              {{ company.companyName }}
            </span>
            <span
              class="company-details__sub text-capitalize"
              [ngClass]="company | companyType : false"
              [matTooltip]="company.canLogin ? (company | companyType : true) : ''">
              {{ company | companyType : false }}
            </span>
            <mat-icon *ngIf="company.isNew" class="new-company">fiber_new</mat-icon>
          </span>
        </button>
      </li>
    }
    <li *ngIf="currentUser.linkedCompanies.length > showThreshold">
      <button
        mat-button
        class="w-100 text-left"
        (click)="showMoreCompanies()">
        <span class="company-details text-capitalize">
          <mat-icon>more_horiz</mat-icon>
          {{ currentUser.linkedCompanies.length - showThreshold }} more
        </span>
      </button>
    </li>
    <li class="divider"></li>
    @for (item of menuItems; track item.label) {
      <li>
        @if (item.link) {
          <a
            mat-button
            color="primary"
            class="link"
            [routerLink]="item.link"
          >
            <mat-icon>{{ item.icon }}</mat-icon>
            <span class="text-capitalize">{{ item.name }}</span>
          </a>
        } @else {
          <button
            mat-button
            color="primary"
            class="link"
            (click)="logOut()"
          >
            <mat-icon>{{ item.icon }}</mat-icon>
            <span class="text-capitalize">{{ item.name }}</span>
          </button>
        }
      </li>
    }
  </ul>
</mat-menu>

