<div [ngStyle]="{'padding-top.px': isVirtualPremium ? 24 : 0}">
  @if (!isMenu) {
    <h2>Notifications</h2>
  }
 @let notifications = notifications$ | async;

  <ul [ngClass]="{'for-menu': isMenu}">
    @for (notification of notifications?.entryBeans; track notification.type) {
      @switch (notification.type) {
        @case (notificationsType.CONNECT_REQUEST_DUE_DILIGENCE) {
          <li class="card cursor-pointer" (click)="navAction(notification)">
            <div class="icon-container">
              <mat-icon>group_add</mat-icon>
            </div>
          
            <div class="card-center">
              <h3>New connection requests</h3>
              <p>
                <span 
                  class="count"
                  [floatUi]="companiesPopover"
                  [showTrigger]="popTriggers.hover"
                  [timeoutAfterShow]="3000"
                  [showDelay]="100"
                  [appendTo]="'body'">
                  {{ notification.from.length === 1 
                    ? 'One counterparty ' 
                    : notification.from.length + ' counterparties ' }}
                </span>
                <span>would like to connect with you.</span>
              </p>
            </div>
          </li>
        }
        @case (notificationsType.CONNECT_SHARE_DUE_DILIGENCE) {
          <li class="card cursor-pointer" (click)="navAction(notification)">
            <div class="icon-container" >
              <mat-icon>group_add</mat-icon>
            </div>
          
            <div class="card-center">
              <h3>New connections</h3>
              <p>
                <span>You have made</span>
                <span 
                  class="count"
                  [floatUi]="companiesPopover"
                  [showTrigger]="popTriggers.hover"
                  [timeoutAfterShow]="3000"
                  [showDelay]="100"
                  [appendTo]="'body'">
                  {{ notification.from.length === 1 
                      ? ' one new connection ' 
                      : ' ' + notification.from.length + ' new connections'}}
                </span>
                <span>in the past 10 days.</span>
              </p>
            </div>
          </li>
        }
        @case (notificationsType.COMPLETE_OWN_PROFILE) {
          <li class="card">
            <div class="icon-container">
               <mat-icon>edit_calendar</mat-icon>
            </div>
          
            <div class="card-center">
              <h3>Complete ‘My profile’</h3>
              <p>
                <span 
                  class="count"
                  [floatUi]="companiesPopover"
                  [showTrigger]="popTriggers.hover"
                  [timeoutAfterShow]="3000"
                  [showDelay]="100"
                  [appendTo]="'body'">
                  {{ notification.from.length === 1 
                    ? 'One counterparty ' 
                    : notification.from.length + ' counterparties ' }}
                </span>
                <span>{{ notification.from.length === 1 ? 'requests' : 'request' }} your most recent information.</span>
              </p>
            </div>
          </li>
        }
        @case (notificationsType.FOLLOWUP_QUESTION) {
          <li class="card cursor-pointer" (click)="navAction(notification)">
            <div class="icon-container" >
              <mat-icon>help_outline</mat-icon>
            </div>
          
            <div class="card-center">
              <h3>Answer follow-up questions</h3>
                <p>
                  <span 
                    class="count"
                    [floatUi]="companiesPopover"
                    [showTrigger]="popTriggers.hover"
                    [timeoutAfterShow]="3000"
                    [showDelay]="100"
                    [appendTo]="'body'">
                    {{ notification.from.length === 1 
                      ? 'One counterparty ' 
                      : notification.from.length + ' counterparties ' }}
                  </span>
                  <span>{{ notification.from.length === 1 ? 'asks' : 'ask' }} you to answer follow-up questions.</span>
                </p>
            </div>
          </li>
        }
        @case (notificationsType.RECEIVE_FOLLOWUP_ANSWER) {
          <li class="card cursor-pointer" (click)="navAction(notification)">
            <div class="icon-container" >
              <mat-icon>help_outline</mat-icon>
            </div>
          
            <div class="card-center">
              <h3>Follow-Up answer received</h3>
              <p>
                <span 
                  class="count"
                  [floatUi]="companiesPopover"
                  [showTrigger]="popTriggers.hover"
                  [timeoutAfterShow]="3000"
                  [showDelay]="100"
                  [appendTo]="'body'">
                  {{ notification.from.length === 1 
                    ? 'One counterparty ' 
                    : notification.from.length + ' counterparties ' }}
                </span>
                <span>answered to your questions in the past 10 days.</span>
              </p>
            </div>
          </li>
        }
        @case (notificationsType.ACKNOWLEDGE_SUBMIT_DDQ) {
          <li class="card cursor-pointer" (click)="navAction(notification)">
            <div class="icon-container" >
              <picture>
                <img class="svg-icon" [src]="'assets/svg/icon-ddq.svg'" alt="DDQ">
              </picture>
            </div>
          
            <div class="card-center">
              <h3>DDQ submitted</h3>
              <p>
                You submitted your questionnaire 
                <span *ngIf="notification.timestamp">on {{ notification.timestamp | date : 'mediumDate' }}</span>.
              </p>
            </div>
          </li>
        }
        @case (notificationsType.ACKNOWLEDGE_SUBMIT_KPI) {
          <li class="card cursor-pointer" (click)="navAction(notification)">
            <div class="icon-container" >
              <picture>
                <img class="svg-icon" [src]="'assets/svg/icon-charts.svg'" alt="KPIs">
              </picture>
            </div>

            <div class="card-center">
              <h3>KPI report submitted</h3>
              <p>
                You submitted your KPI 
                <span *ngIf="notification.timestamp">on {{ notification.timestamp | date : 'mediumDate' }}</span>.
              </p>
            </div>
          </li>
        }
        @case (notificationsType.ACKNOWLEDGE_SUBMIT_DFT) {
          <li class="card cursor-pointer" (click)="navAction(notification)">
            <div class="icon-container" >
              <mat-icon>event_repeat</mat-icon>
            </div>
          
            <div class="card-center">
              <h3>DFT report submitted</h3>
              <p>
                You submitted your DFT 
                <span *ngIf="notification.timestamp">on {{ notification.timestamp | date : 'mediumDate' }}</span>.
              </p>
            </div>
          </li>
        }
        @case (notificationsType.INVITE_COUNTERPARTIES) {
          <li class="card cursor-pointer" (click)="navAction(notification)">
            <div class="icon-container" >
              <mat-icon>forward_to_inbox</mat-icon>
            </div>
          
            <div class="card-center">
              <h3>Counterparty invitations</h3>
              <p>
                <span 
                  class="count"
                  [floatUi]="companiesPopover"
                  [showTrigger]="popTriggers.hover"
                  [timeoutAfterShow]="3000"
                  [showDelay]="100"
                  [appendTo]="'body'">
                  {{ notification.from.length === 1 
                    ? 'One counterparty ' 
                    : notification.from.length + ' counterparties ' }}
                </span>
                <span>{{ notification.from.length === 1 ? 'has not' : 'have not' }} received your invitation.</span>
              </p>
            </div>
          </li>
        }
        @case (notificationsType.ACTION_RAISED_BY_UME) {
          <li class="card cursor-pointer" (click)="navAction(notification)">
            <div class="icon-container" >
              <mat-icon>error_outline</mat-icon>
            </div>
          
            <div class="card-center">
              <h3>Action raised by ume</h3>
                <p>
                  <span>UME raised actions for </span>
                  <span 
                    class="count"
                    [floatUi]="companiesPopover"
                    [showTrigger]="popTriggers.hover"
                    [timeoutAfterShow]="3000"
                    [showDelay]="100"
                    [appendTo]="'body'">
                    {{ notification.from.length === 1 
                      ? ' one counterparty'
                      : ' ' + notification.from.length + ' counterparties' }}
                  </span>
                </p>
            </div>
          </li>
        }
        @case (notificationsType.RECEIVE_DDQ) {
          <li class="card cursor-pointer" (click)="navAction(notification)">
            <div class="icon-container" >
              <picture>
                <img class="svg-icon" [src]="'assets/svg/icon-ddq.svg'" alt="DDQ">
              </picture>
            </div>

            <div class="card-center">
              <h3>DDQ received</h3>
              <p>
                <span 
                  class="count"
                  [floatUi]="companiesPopover"
                  [showTrigger]="popTriggers.hover"
                  [timeoutAfterShow]="3000"
                  [showDelay]="100"
                  [appendTo]="'body'">
                  {{ notification.from.length === 1 
                    ? 'One counterparty ' 
                    : notification.from.length + ' counterparties ' }}
                </span>
                <span>submitted their DDQ in the past 10 days.</span>
              </p>
            </div>
          </li>
        }
        @case (notificationsType.RECEIVE_FILE) {
          <li class="card cursor-pointer" (click)="navAction(notification)">
            <div class="icon-container" >
              <div class="icon-container">
                <picture>
                  <img class="svg-icon" [src]="'assets/svg/icon-file-grey.svg'" alt="Files">
                </picture>
              </div>
            </div>
          
            <div class="card-center">
              <h3>New files received</h3>
              <p>
                <span 
                  class="count"
                  [floatUi]="companiesPopover"
                  [showTrigger]="popTriggers.hover"
                  [timeoutAfterShow]="3000"
                  [showDelay]="100"
                  [appendTo]="'body'">
                  {{ notification.from.length === 1 
                    ? 'One counterparty ' 
                    : notification.from.length + ' counterparties ' }}
                </span>
                <span>submitted their files in the past 10 days.</span>
              </p>
            </div>
          </li>
        }
        @case (notificationsType.RECEIVE_KPI) {
          <li class="card cursor-pointer" (click)="navAction(notification)">
            <div class="icon-container" >
              <picture>
                <img class="svg-icon" [src]="'assets/svg/icon-charts.svg'" alt="KPIs">
              </picture>
            </div>
          
            <div class="card-center">
              <h3>KPI report received</h3>
              <p>
                <span 
                  class="count"
                  [floatUi]="companiesPopover"
                  [showTrigger]="popTriggers.hover"
                  [timeoutAfterShow]="3000"
                  [showDelay]="100"
                  [appendTo]="'body'">
                  {{ notification.from.length === 1 
                    ? 'One counterparty ' 
                    : notification.from.length + ' counterparties ' }}
                </span>
                <span>submitted their KPI report in the past 10 days.</span>
              </p>
            </div>
          </li>
        }
        @case (notificationsType.RECEIVE_DFT) {
          <li class="card cursor-pointer" (click)="navAction(notification)">
            <div class="icon-container" >
              <mat-icon>event_repeat</mat-icon>
            </div>
          
            <div class="card-center">
              <h3>DFT report received</h3>
              <p>
                <span 
                  class="count"
                  [floatUi]="companiesPopover"
                  [showTrigger]="popTriggers.hover"
                  [timeoutAfterShow]="3000"
                  [showDelay]="100"
                  [appendTo]="'body'">
                  {{ notification.from.length === 1 
                    ? 'One counterparty ' 
                    : notification.from.length + ' counterparties ' }} 
                </span>
                <span>submitted their DFT report in the past 10 days.</span>
              </p>
            </div>
          </li>
        }
        @case (notificationsType.RECEIVE_KYC) {
          <li class="card cursor-pointer" (click)="navAction(notification)">
            <div class="icon-container" >
              <mat-icon>manage_accounts</mat-icon>
            </div>
          
            <div class="card-center">
              <h3>KYC received</h3>
              <p>
                <span 
                  class="count"
                  [floatUi]="companiesPopover"
                  [showTrigger]="popTriggers.hover"
                  [timeoutAfterShow]="3000"
                  [showDelay]="100"
                  [appendTo]="'body'">
                  {{ notification.from.length === 1 
                    ? 'One counterparty ' 
                    : notification.from.length + ' counterparties ' }}
                </span>
                <span>submitted their KYC in the past 10 days.</span>
              </p>
            </div>
          </li>
        }
      }
 
      <float-ui-content #companiesPopover>
        <ul class="popper-list">
          @for (c of notification.from; track c.brId) {
            <li>
              @if (c.company.country.code) {
                <i [ngClass]="['icon-flag-' + c.company.country.code]"></i>
              }
              <span class="ellipsis">{{ c.company.companyName }}</span>
            </li>
          }
        </ul>
      </float-ui-content>
    }
  </ul>
</div>
