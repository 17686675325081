import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { Router } from '@angular/router';
import { ItemInfo } from '@core/models/navigation-item.model';

import { setRestrictedAccessRoute } from '../../guards/navigationLink.guard';


@Component( {
  selector:    'ume-main-nav-menu-panel',
  templateUrl: './main-nav-menu-panel.component.html',
  styleUrls:   [ './main-nav-menu-panel.component.scss' ],
} )
export class MainNavMenuPanelComponent {
  @Input() items!: ItemInfo[];
  @Output() triggerCustomEvent = new EventEmitter();

  @ViewChild( 'menu', { static: true } ) menu!: MatMenu;

  constructor( private _router: Router ) { }

  public handleClick( item: ItemInfo, event: MouseEvent ): void {
    if ( item.payment ) {
      event.preventDefault();
      this._router.navigate( [ '/', 'plans', 'comparison' ] );
      return;
    }

    if ( item.disabled ) {
      event.preventDefault();
      const label: string | string[] = setRestrictedAccessRoute( item.menuLabel );
      this._router.navigate( [ '/', ...(Array.isArray( label ) ? label : [ label ]), 'restricted-user-rights' ] );
      return;
    }

    if ( item.customEvent ) {
      this.triggerCustomEvent.emit();
    }
  }
}
