<mat-menu #menu class="main-menu-panel">
  @if ( items ) {
    @for ( item of items; track item.label ) {
      <!-- case for external links -->
      @if ( item.target && !item.customEvent ) {
        <a
          mat-menu-item
          routerLinkActive="link_active"
          [target]="item.target"
          [href]="item.link"
          [class.disabled]="item.disabled || item.payment"
          (click)="handleClick(item, $event)">
          <span class="menu-label">{{ item.name }}</span>
        </a>
      }
      @if ( !item.target && !item.customEvent ) {
        <a
          mat-menu-item
          routerLinkActive="link_active"
          [routerLink]="item.link | companyTypeBasedRoute"
          [class.disabled]="item.disabled || item.payment"
          (click)="handleClick(item, $event)">
          <span class="menu-label">{{ item.name }}</span>
        </a>
      }

      @if ( item.customEvent ) {
        <button
          mat-menu-item
          class="link"
          [class.disabled]="item.disabled || item.payment"
          (click)="handleClick(item, $event)">
          <span class="menu-label">{{ item.name }}</span>
        </button>
      }
    }
  }
</mat-menu>
