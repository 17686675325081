import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ICompanyInfo } from '@core/models/app.model';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OVERSIGHT_STATUS } from '@shared/constants/ume-constants';
import { default as moment } from 'moment';
import { NgxFloatUiTriggers } from 'ngx-float-ui';
import { Observable, tap } from 'rxjs';
import { 
  ddqNotifications, 
  getDashboardStoreValue, 
  notifications$
} from 'src/app/routes/dashboard/repository/dashboard.repository';

import {
  DASHBOARD_NOTIFICATION_TYPE,
  DDQ_SUMMARY_STATUS,
  INotification,
  INotificationDetails,
  REPORT_PREPARATION_STATUS,
} from '../../routes/dashboard/models';


@UntilDestroy()
@Component( {
  selector:        'ume-user-notifications',
  templateUrl:     './user-notifications.component.html',
  styleUrls:       [ './user-notifications.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
} )
export class UserNotificationsComponent {
  @Input() isMenu = false;
  @Input() isVirtualPremium = false;

  readonly notificationsType = DASHBOARD_NOTIFICATION_TYPE;
  readonly reportPreparationStatus = REPORT_PREPARATION_STATUS;
  readonly popTriggers = NgxFloatUiTriggers;

  public canConnect: boolean = false;
  public notifications$: Observable<INotification> = notifications$
    .pipe( tap( ( notifications: INotification ) => this.canConnect = notifications.canConnect ) );

  constructor( private router: Router ) {}
   
  // eslint-disable-next-line complexity
  public navAction( notification: INotificationDetails ): void {
    const forTenDays = moment().subtract( 10, 'days' ).format( 'yyyy-MM-DD' );

      switch ( notification.type ) {
        case DASHBOARD_NOTIFICATION_TYPE.CONNECT_REQUEST_DUE_DILIGENCE:
          if ( this.canConnect ) {
            this.router.navigate(
              [ '/', 'company', 'my-network', 'list' ],
              {
                queryParams: {
                  oversightStatus: OVERSIGHT_STATUS.REQUEST_RECEIVED,
                  shareAll:        true,
                },
              } ).then();
          } else {
            this.router.navigate(
              [ '/', 'company', 'my-network', 'list' ],
              {
                queryParams: {
                  oversightStatus:  OVERSIGHT_STATUS.REQUEST_RECEIVED,
                  requestsNotReady: true,
                },
              } ).then();
          }
          break;
        case DASHBOARD_NOTIFICATION_TYPE.CONNECT_SHARE_DUE_DILIGENCE:
          this.router.navigate(
            [ '/', 'company', 'my-network', 'list' ],
            {
              queryParams: {
                dateFrom: forTenDays,
              },
            } ).then();
          break;
        case DASHBOARD_NOTIFICATION_TYPE.FOLLOWUP_QUESTION:
          this.router.navigate( 
            [ '/', 'my-profile', 'followups' ],
            {
              queryParams: {
                status: 'NEW',
              },
            } ).then();
          break;
        case DASHBOARD_NOTIFICATION_TYPE.RECEIVE_FOLLOWUP_ANSWER:
          if ( notification.from?.length === 1 ) {
            this.router.navigate(
              [ '/', 'company', 'my-network', 'br', notification.from[0].brId, 'controlled', 'counterparty', 'followup' ],
            ).then();
          } else {
            this.router.navigate(
              [ '/', 'company', 'my-network', 'questions' ],
              {
                queryParams: { actionRaised: 'YES' },
              },
            ).then();
          }
          break;
        case DASHBOARD_NOTIFICATION_TYPE.ACKNOWLEDGE_SUBMIT_DDQ:
          if ( getDashboardStoreValue().ddqSummary?.status === DDQ_SUMMARY_STATUS.DRAFT ) {
            this.router.navigate( [ '/', 'questionnaire', 'edit' ] ).then();
          }
      
          if ( ddqNotifications().ddqNotStarted ) {
            this.router.navigate( [ '/', 'questionnaire', 'start' ] ).then();
          }
          break;
        case DASHBOARD_NOTIFICATION_TYPE.ACKNOWLEDGE_SUBMIT_KPI:
          this.router.navigate( [ '/', 'my-profile', 'kpi', 'form-view' ] ).then();
          break;
        case DASHBOARD_NOTIFICATION_TYPE.ACKNOWLEDGE_SUBMIT_DFT:
          this.router.navigate( [ '/', 'my-profile', 'reporting', 'report-history' ] ).then();
          break;
        case DASHBOARD_NOTIFICATION_TYPE.INVITE_COUNTERPARTIES:
          this.router.navigate(
            [ '/', 'company', 'my-network', 'list' ],
            {
              queryParams: { oversightStatus: OVERSIGHT_STATUS.NOT_INVITED },
            },
          ).then();
          break;
        case DASHBOARD_NOTIFICATION_TYPE.ACTION_RAISED_BY_UME:
          if ( notification.from?.length === 1 ) {
            this.router.navigate(
              [ '/', 'company', 'my-network', 'br', notification.from[0].brId, 'controlled', 'counterparty', 'onboarding-notes' ],
            ).then();
          } else {
            this.router.navigate(
              [ '/', 'company', 'my-network', 'list' ],
              {
                queryParams: { actionRaised: 'YES' },
              },
            ).then();
          }
          break;
        case DASHBOARD_NOTIFICATION_TYPE.RECEIVE_DDQ:
          if ( notification.from?.length === 1 ) {
            this.router.navigate(
              [ '/', 'company', 'my-network', 'br', notification.from[0].brId, 'controlled', 'counterparty', 'questionnaire' ],
              {
                queryParams: {
                  dateFrom: forTenDays,
                },
              } ).then();
          } else {
            this.router.navigate(
              [ '/', 'company', 'my-network', 'information-received', 'ddq' ],
              {
                queryParams: {
                  dateFrom: forTenDays,
                },
              } ).then();
          }
          break;
        case DASHBOARD_NOTIFICATION_TYPE.RECEIVE_FILE:
          if ( notification.from?.length === 1 ) {
            this.router.navigate(
              [ '/', 'company', 'my-network', 'br', notification.from[0].brId, 'controlled', 'counterparty', 'files' ],
              {
                queryParams: {
                  dateFrom: forTenDays,
                },
              } ).then();
          } else {
            this.router.navigate(
              [ '/', 'company', 'my-network', 'information-received', 'files' ],
              {
                queryParams: {
                  dateFrom: forTenDays,
                },
              } ).then();
          }
          break;
        case DASHBOARD_NOTIFICATION_TYPE.RECEIVE_KPI:
          if ( notification.from?.length === 1 ) {
            //Todo: don't forget about changes fpr kpi counterparty changes
            this.router.navigate(
              [ '/', 'company', 'my-network', 'br', notification.from[0].brId, 'controlled', 'counterparty', 'kpi' ],
              {
                queryParams: {
                  dateFrom: forTenDays,
                },
              } ).then();
          } else {
            this.router.navigate(
              [ '/', 'company', 'my-network', 'information-received', 'kpi' ],
              {
                queryParams: {
                  dateFrom: forTenDays,
                },
              } ).then();
          }
          break;
        case DASHBOARD_NOTIFICATION_TYPE.RECEIVE_DFT:
          if ( notification.from?.length === 1 ) {
            this.router.navigate(
              [ '/', 'company', 'my-network', 'br', notification.from[0].brId, 'controlled', 'counterparty', 'consumer-duty', 'dft' ],
              {
                queryParams: {
                  dateFrom: forTenDays,
                },
              } ).then();
          } else {
            this.router.navigate(
              [ '/', 'company', 'my-network', 'information-received', 'dft' ],
              {
                queryParams: {
                  dateFrom: forTenDays,
                },
              } ).then();
          }
          break;
        case DASHBOARD_NOTIFICATION_TYPE.RECEIVE_KYC:
          if ( notification.from?.length === 1 ) {
            this.router.navigate(
              [ '/', 'company', 'my-network', 'br', notification.from[0].brId, 'controlled', 'counterparty', 'kyc' ],
              {
                queryParams: {
                  dateFrom: forTenDays,
                },
              } ).then();
          } else {
            this.router.navigate(
              [ '/', 'company', 'my-network', 'information-received', 'kyc' ],
              {
                queryParams: {
                  dateFrom: forTenDays,
                },
              } ).then();
          }
          break;
        default:
          break;
      }
  } 
}
