import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { AuthService } from '@auth/repository';
import { isLoggedIn$, userSelectedCompany$ } from '@auth/repository/auth.repository';
import { ICompanyLookupBean } from '@core/models/app.model';
import { Guid } from '@core/models/guid';
import { INavigationItem, ItemInfo } from '@core/models/navigation-item.model';
import { PermissionService } from '@core/permissions/permission.service';
import { environment } from '@env/environment';
import { downloadFileAction } from '@helpers/download-file';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DIALOG_SIZE } from '@shared/constants/ume-constants';
import { UserService } from '@user/repository';
import { EMPTY, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { notifications$ } from 'src/app/routes/dashboard/repository/dashboard.repository';

import { NewUserSignupDialogComponent } from '../../routes/dashboard/components/new-user-signup-dialog/new-user-signup-dialog.component';
import { NavigationComponent } from '../navigation/navigation.component';
import { appNavigationItems$ } from '../repository/navigation-items.repository';


@UntilDestroy()
@Component( {
  selector:    'ume-base-layout',
  templateUrl: './base-layout.component.html',
  styleUrls:   [ './base-layout.component.scss' ],
} )
export class BaseLayoutComponent implements OnInit {
  public navigationItems: INavigationItem[] | null = null;
  public helpItems: ItemInfo[] = getHelpItems();
  public $isLoggedIn: Observable<boolean> = isLoggedIn$;
  public termsAndConditions$ = userSelectedCompany$
    .pipe(
      distinctUntilChanged(),
      filter( ( res: ICompanyLookupBean | undefined ) => Boolean( res ) ),
      switchMap( ( c ) => {
        return c
          ? this.autService.getCompanyApplicableTerms( c.guid )
          : EMPTY;
      } ),
    );
  public notifications$: Observable<boolean> = notifications$
    .pipe( map( notifications => Boolean( notifications?.entryBeans?.length ) ) );

  public loading$ = this.router.events
    .pipe(
      filter( ( e ) => e instanceof NavigationStart ||
                       e instanceof NavigationEnd ||
                       e instanceof NavigationComponent || e instanceof NavigationError ||
                       e instanceof NavigationCancel ),
      map( e => e instanceof NavigationStart ),
    );

  public ddqNotifications = false;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private autService: AuthService,
    private userService: UserService,
    private permissions: PermissionService,
  ) {}

  ngOnInit(): void {
    // app wide subscription
    appNavigationItems$
      .pipe( untilDestroyed( this ) )
      .subscribe( ( items ) => {
        this.navigationItems = items;
      } );
  }

  public get notUmeOps(): boolean {
    return !this.permissions.isUmeOps();
  }

  public downloadTerms( term: { name: string; guid: Guid; } ): void {
    this.autService.downloadTerms( term )
      .pipe( untilDestroyed( this ) )
      .subscribe( ( { body, contentDisposition }: { body: ArrayBuffer, contentDisposition: string } ) => {
        downloadFileAction( body, contentDisposition, term.name, 'pdf' );
      } );
  }

  public openSaml( target = environment.qlikApp ): void {
    this.userService.setCookiesToCompany()
      .pipe(
        untilDestroyed( this ),
      )
      .subscribe( () =>
        window.open( `${ environment.qlikUrl }/sense/app/${ target }`, '_blank' ),
      );
  }

  public openMasterAnalytics(): void {
    this.openSaml( environment.adminQlikApp );
  }

  public welcomeGuide(): void {
    this.dialog.open(
      NewUserSignupDialogComponent,
      {
        width:     DIALOG_SIZE.MODAL_MD,
        autoFocus: true,
      },
    );
  }

  public handleCustomEvent(): void {
    this.permissions.isUmeOps()
      ? this.openMasterAnalytics()
      : this.openSaml();
  }
}

export function getHelpItems(): ItemInfo[] {
  return [
    {
      label:     'ABOUT_UME',
      menuLabel: 'ABOUT',
      name:      'About ume',
      link:      'https://www.ume.solutions/',
      target:    '_blank',
      external:  true,
      disabled:  false,
      payment:   false,
      icon:      'error_outline',
    },
    {
      label:     'FAQ',
      menuLabel: 'ABOUT',
      name:      'FAQ',
      link:      '/about/faq',
      disabled:  false,
      payment:   false,
      icon:      'help_outline',
    },
  ];
}
