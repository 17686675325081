<mat-dialog-content>
  <mat-horizontal-stepper linear>
    <mat-step [completed]="true">
      <div class="welcome-dialog step-1">
        <div class="welcome-img">
          <picture>
            <img [src]="'assets/images/welcome-1.jpg'" alt="welcome">
          </picture>
        </div>
        <div class="welcome-content">
          <div class="content">
            <picture class="text-center">
              <img [src]="'assets/images/ume_logo_128.png'" alt="ume">
            </picture>
            <h1 class="text-center">Welcome to ume</h1>
            <p>
              ume is the asset management industry’s platform of reference for the exchange and evaluation of due diligence information.
            </p>
            <p>
              Because the ume platform combines rigour with ease of exchanging due diligence information, it now
              connects hundreds of investment managers with thousands of distributors in 96 countries.
            </p>
            <p>
              Share your own due diligence information with your counterparties and receive theirs in return.
            </p>
          </div>

          <div class="buttons-wrap">
            <button mat-button color="primary" [mat-dialog-close]="false">Skip</button>
            <button mat-flat-button color="primary" matStepperNext>Next</button>
          </div>
        </div>
      </div>
    </mat-step>

    <mat-step>
      <div class="welcome-dialog step-2">
        <div class="welcome-img">
          <picture>
            <img [src]="'assets/images/welcome-3.jpg'" alt="welcome">
          </picture>
        </div>
        <div class="welcome-content">
          <div class="content">
            <h1>Why ume?</h1>

            <ul class="welcome-ul">
              <li>
                <div class="li-icon"><img [src]="'assets/svg/icon-less-effort.svg'" alt="Less effort"></div>
                <div class="li-text">
                  <h4>Less effort</h4>
                  <p>You only need to provide the information once and can then share it with all your counterparties.</p>
                </div>
              </li>
              <li>
                <div class="li-icon"><img [src]="'assets/svg/icon-standard-info.svg'" alt="Standard information requirements"></div>
                <div class="li-text">
                  <h4>Standard information requirements</h4>
                  <p>The platform users share their understanding of the regulations and work together to maintain a
                    standardised set of information requirements, continually evolving in line with new regulatory and
                    industry standards.</p>
                </div>
              </li>
              <li>
                <div class="li-icon"><img [src]="'assets/svg/icon-high-security.svg'" alt="Standard information requirements"></div>
                <div class="li-text">
                  <h4>High Security</h4>
                  <p>Protecting your data is our highest priority. Independent third parties regularly verify that the
                    platform's security is comprehensive and provides the highest level of data protection.</p>
                </div>
              </li>
            </ul>
          </div>

          <div class="buttons-wrap">
            <button mat-button color="primary" class="back" matStepperPrevious>
              <mat-icon>chevron_left</mat-icon>
              Back
            </button>
            <button mat-button color="primary" [mat-dialog-close]="false">Skip</button>
            <button mat-flat-button color="primary" matStepperNext>Next</button>
          </div>
        </div>
      </div>
    </mat-step>

    <mat-step>
      <div class="welcome-dialog step-3">
        <div class="welcome-img">
          <picture>
            <img [src]="'assets/images/welcome-2.jpg'" alt="welcome">
          </picture>
        </div>
        <div class="welcome-content">
          <div class="content">
            <h1>What's next?</h1>

            <ul class="welcome-ul">
              <li>
                <div class="li-icon"><img [src]="'assets/svg/icon-invite-companies.svg'" alt="Less effort"></div>
                <div class="li-text">
                  <h4>Invite counterparties</h4>
                  <p>Grow your network and connect with all your counterparties. Many of them are already on ume.</p>
                </div>
              </li>
              <li>
                <div class="li-icon"><img [src]="'assets/svg/icon-your-profile.svg'" alt="Standard information requirements"></div>
                <div class="li-text">
                  <h4>Complete your own profile</h4>
                  <p>You can then connect and exchange due diligence information with all your counterparties.</p>
                </div>
              </li>
              <li>
                <div class="li-icon"><img [src]="'assets/svg/icon-find-help.svg'" alt="Standard information requirements"></div>
                <div class="li-text">
                  <h4>Find help</h4>
                  <p>Contact support</p>
                  <p>
                    Email <a href="mailto:support@ume.solutions" class="text-primary">support&#64;ume.solutions</a>
                    or call us on <a href="tel:+35228812702" class="text-default">+352 288 12 702</a>,
                    and a Customer Support Manager will assist you.
                  </p>
                </div>
              </li>
            </ul>
          </div>

          <div class="buttons-wrap">
            <button mat-button color="primary" class="back" matStepperPrevious>
              <mat-icon>chevron_left</mat-icon>
              Back
            </button>
            <button mat-flat-button color="primary" [mat-dialog-close]="false">Let's get to work</button>
          </div>
        </div>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</mat-dialog-content>
