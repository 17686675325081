import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SelectCompanyDialogComponent } from '@shared/dialogs/select-company-dialog/select-company-dialog.component';
import { PipesModule } from '@shared/pipes';


@NgModule( {
  declarations: [
    SelectCompanyDialogComponent,
  ],
  imports:      [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    PipesModule,
  ],
  exports:      [
    SelectCompanyDialogComponent,
  ],
} )
export class SelectCompanyDialogModule {
}
